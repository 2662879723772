import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Seo from "../components/Seo/Seo";

const FilmWrapper = styled.div`
  margin: 0 0 8em 0;

  .gatsby-resp-iframe-wrapper {
    margin-bottom: 1.5rem;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
`;

// const Date = styled.span`
//   font-family: futura-pt-bold, sans-serif;
//   font-size: 1rem;
//   font-weight: bold;
//   line-height: normal;
//   margin: 0 1rem 1rem 0;
//   letter-spacing: 2.5px;
// `;

const Title = styled.h3``;

const Text = styled.div``;

const Film = ({ film }) => (
  <FilmWrapper>
    <Header>
      <Title>{film.node.title}</Title>
    </Header>

    {film.node.text && (
      <Text
        dangerouslySetInnerHTML={{
          __html: film.node.text.childMarkdownRemark.html,
        }}
      />
    )}
  </FilmWrapper>
);

const WorkPage = ({ data }) => {
  const text = data.contentfulPage.text;
  const films = data.allContentfulFilm.edges;

  return (
    <div>
      <Seo title="Work" />
      <div
        dangerouslySetInnerHTML={{ __html: text.childMarkdownRemark.html }}
      />
      {films.map((film) => (
        <Film key={film.node.id} film={film} />
      ))}
    </div>
  );
};

export default WorkPage;

export const query = graphql`
  query FilmQuery {
    contentfulPage(slug: { eq: "work" }) {
      id
      title
      slug
      text {
        childMarkdownRemark {
          html
        }
      }
    }
    allContentfulFilm(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          id
          title
          date(formatString: "YYYY")
          text {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
